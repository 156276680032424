<template lang="pug">
#app.mobile-page(:class="[lang]")
  .background_panel
    img.bg(src='@/assets/images/mobile/background.png')
    img.logo(v-if="show" src='@/assets/images/mobile/logo.png')
    img.logo(v-if="!show" src='@/assets/images/mobile/sp-logo.png')
    .download
      download-icon(size="mobile")
    .title
      p.text_one {{head}}
      p.text_two {{head_text}}
  .mask
  .users
    user-info(platform="mobile")
  .desc_panel
    img.phone(src='@/assets/images/mobile/phone.png')
    a.download_btn(:href="apkLink", @click="log('ud_web_download_button_click')")
      span.download_text {{$TT('APK DOWNLOAD')}}
      img.btn_icon(src='@/assets/images/mobile/download-icon.png')
  .faq_panel
    FAQ(device="mobile")
  .contact_panel
    .icons
      a(href="mailto:udictionary01@gmail.com")
        img.email(src='@/assets/images/mobile/icon_email.png')
      a(href="https://www.youtube.com/channel/UCJPIDjpTCVoc94Ypy47NryA")
        img.youtube(src='@/assets/images/mobile/icon_youtube.png')
      a(href="https://www.facebook.com/UDictionary")
        img.facebook(src='@/assets/images/mobile/icon_fb.png')
      a(href="https://www.instagram.com/udictionary_eng/")
        img.ins(src='@/assets/images/mobile/icon_ins.png')
    .choose_language
        .titlebox {{siteLan}}
        span.selectlan(
            v-for="(item, index) in langList"
            :key="index"
            @click="chooseLan(item)"
            ) {{item}}
    .copyright
      span Copyright {{currentYear}}, NetEase, Inc. All Rights Reserved.
    .download_panel
      .text
        span.red {{red_appText}}
        span.black {{black_appText}}
        span.light  {{appText}}
      a.download_btn(:href="apkLink", @click="log('ud_web_download_button_click')")
        span.btn_text {{$TT('APK DOWNLOAD')}}
        img.btn_icon(src='@/assets/images/mobile/download-icon.png')
</template>

<script>
import userInfo from '@/views/Home/components/usersInfo.vue'
import FAQ from '@/views/FAQ/FAQ.vue'
import service from '@/config/faq/questions'
import { getMobileLangClass, getLangClass } from '@/utils/index'
import downloadIcon from '@/components/pc/downloadIcon.vue'
import { data } from '@/config/data'
import links from '@/config/links'

export default {
  components: { userInfo, FAQ, downloadIcon },
  data() {
    return {
      show: true,
      head: 'U-DICTIONARY',
      siteLan: 'site language',
      selectLan: '',
      navLan: '',
      head_text: "WORLD'S BEST TRANSLATION APP",
      red_appText: 'U-D',
      black_appText: 'ictionary',
      appText: 'App',
      langList: [],
      questionList: [],
      currentYear: '',
      downloadLink: '',
      apkLink: links.apkLink,
      lang: 'en',
      href: '',
      // 因为国际化问题，所以将文字单独跳出以变进行国际化。
      title_text: `
      <p class="text_one">U-Dictionary</p>
      <p class="text_two>WORLD'S BEST TRANSLATION APP</p>
      `,
    }
  },
  mounted() {
    this.langList = data.languageList;
    this.href = window.location.href;
    if (this.href.indexOf('traductoru.com') === -1) {
      this.navLan = navigator.languages ? navigator.languages[0] : navigator.language;
    } else {
      this.navLan = 'es';
    }
    this.selectLan = getMobileLangClass(this.navLan);
    this.$store.dispatch('setLanguage', this.selectLan);
    this.$setLang(this.selectLan);
  },
  watch: {
    '$store.state.selectLang': {
      handler(val) {
        this.head = this.$TT('U-DICTIONARY');
        this.siteLan = this.$TT('site language');
        this.head_text = this.$TT('WORLD\'S BEST TRANSLATION APP');
        this.red_appText = this.selectLan === 'English' ? 'U-D' : ' ';
        this.black_appText = this.$TT('ictionary');
        this.appText = this.selectLan === 'English' ? 'App' : ' ';
        this.show = this.selectLan !== 'Español';
        document.title = this.$TT('U-Dictionary:World\'s Best Translation APP')
        this.lang = getLangClass(val)
      },
      immediate: true,
    },
  },
  created() {
    this.questionList = service.questionList;
    const date = new Date();
    this.currentYear = date.getFullYear();
    this.log('ud_web_download_button_view')
  },
  methods: {
    chooseLan(item) {
      this.$store.dispatch('setLanguage', item);
      this.$setLang(item);
      this.selectLan = item;
    },
  },
}
</script>

<style lang="stylus">
@import '../../styles/_reset.styl'
.mobile-page
  position relative
  font-family Gilroy
  .background_panel
    width 100%
    .bg
      width 100%
    .logo, .download
      position absolute
      width 0.28rem
      top 0.23rem
      left 0.23rem
      z-index 100
    .download
      left 1.6rem
    .title
      position absolute
      z-index 999
      width 92%
      transform translate(0.3rem, -2rem)
      font-weight 900
      font-size 0.4rem
      line-height 0.38rem
      text-transform uppercase
      .text_one
        color #fff
        margin-bottom 0.1rem
      .text_two
        color #000
  .users
    padding-top 0.1rem
    background #FCFDEF
  .desc_panel
    position relative
    width 100%
    background #FCFDEF
    .phone
      width 100%
    .download_btn
      position absolute
      width 81%
      left 50%
      transform translateX(-50%)
      top 80%
      z-index 999
      box-sizing border-box
      text-align center
    .download_text
      font-size 0.16rem
      margin-right 0.1rem
  .btn_box
    width  1.2rem
  .mask
    position absolute
    transform translateY(-2.4rem)
    background linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #FCFDEF 46.23%)
    width 100%
    height 2.46rem
    z-index 100
  .faq_panel
    margin-top -0.7rem
    .faq
      width 100%
  .contact_panel
    margin-top 0.2rem
    margin-bottom 0.7rem
    height 1.86rem
    width 100%
    background #1e1e20
    .icons
      padding-top 0.4rem
      display flex
      justify-content space-around
      .email
        width 0.36rem
      .youtube
        width 0.36rem
      .facebook
        width 0.36rem
      .ins
        width 0.36rem
    .choose_language
      width 3rem
      color white
      margin 0.05rem auto
      letter-spacing 0.7px
     .titlebox
       color white
       font-size 0.18rem
       font-weight bold
       margin-bottom 0.05rem
     .selectlan
       margin-right 0.12rem
       font-size 0.1rem
    .copyright
      width 3rem
      margin 0.2rem auto
      font-size 10px
      line-height 0.14rem
      letter-spacing 0.7px
      color rgba(255, 255, 255, 0.5)
      opacity 0.2
  .download_panel
    display flex
    align-items center
    position fixed
    bottom 0
    z-index 1000
    display flex
    justify-content space-between
    padding 0.13rem 0.2rem 0.1rem
    box-sizing border-box
    align-items center
    width 100%
    background #fff
    border 0.5px solid #E3E4E6
    box-shadow 0px 2px 20px rgba(0, 0, 0, 0.143384)
    .text
      font-size 0.16rem
      .red
        line-height 0.2rem
        color #ff592b
        font-weight 900
      .black
        line-height 0.2rem
        font-weight 900
      .light
        line-height 0.16rem
        font-weight light
  .download_btn
    background #FF592B
    border-radius 0.06rem
    padding 0.12rem
    font-size 0.12rem
    line-height 0.14rem
    color #fff
    font-weight 600
    .btn_text
      margin-right 0.06rem
    .btn_icon
      vertical-align -30%
  .download_component
    .ios_mobile.ios_icon, .google_mobile.google_icon
      width 0.94rem
    .ios_mobile.ios_icon
      margin-left 0.08rem
  &.ar
    .download_panel, .download_btn
      direction rtl
    .download_text
      margin-right 0
      margin-left 0.1rem
    .btn_text
      margin-right 0
      margin-left 0.06rem
</style>
